<template>
  <div>
    <v-card
      class="mx-auto"
      width="100%"
    >
      <v-container
        fill-height
        fluid
      >
        <section class="content col-12 pa-0">
          <loading
            :active.sync="requestLoading"
            :is-full-page="false"
          />
          <v-row>
            <v-col cols="3">
              <v-card
                class="box-alert-pending"
                height="140"
              >
                <v-card-title class="white--text align-end">
                  <div class="headline">
                    {{ alertInfo.pending }}
                  </div>
                </v-card-title>
                <v-card-subtitle class="white--text align-end">
                  {{ $t('dashboard.cards.pending.subtitle') }}
                </v-card-subtitle>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    text
                    dark
                    :to="{ name: 'AlertsHistory' }"
                    data-cy="to_AlertsHistory_dashboard_btn"
                  >
                    {{ $t('dashboard.cards.pending.buttons.view') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="3">
              <v-card
                class="box-single-scan"
                min-height="140px"
              >
                <v-card-title class="white--text align-end">
                  {{ $t('dashboard.cards.screening.subtitle') }}
                </v-card-title>
                <v-card-subtitle><p>&nbsp;</p></v-card-subtitle>
                <!-- <v-card-text class="white--text align-end"> -->
                <!-- <v-text-field label="Name" v-model="form.name"
                                        :error="$v.form.name.$error"
                                        :error-messages="$v.form.name.$error ? 'Name is required' : ''"></v-text-field>
                                    <CountrySelector v-if="form.name" label="Nationality" placeholder="Please select country" v-model="form.nationality"></CountrySelector> -->
                <!-- <div class="headline">Single Screening</div> -->
                <!-- <p>&nbsp; </p> -->
                <!-- </v-card-text> -->
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    text
                    dark
                    :to="{ name: 'SingleScan' }"
                    data-cy="to_SingleScan_dashboard_btn"
                  >
                    {{ $t('dashboard.cards.screening.subtitle') }}
                  </v-btn>
                  <!-- <v-spacer></v-spacer>
                                    <v-btn text dark v-if="form.name">Clear</v-btn>
                                    <v-btn text dark class="ml-2" @click="onScan">Search</v-btn> -->
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="3">
              <v-card
                class="box-company"
                height="140"
              >
                <v-card-title class="align-end">
                  {{ alertInfo.cnt_all_company }}
                </v-card-title>
                <v-card-subtitle class="align-end">
                  {{ $t('dashboard.cards.company.subtitle') }}
                </v-card-subtitle>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    text
                    dark
                    :to="{ name: 'Companies' }"
                    data-cy="to_Companies_dashboard_btn"
                  >
                    {{ $t('dashboard.cards.company.buttons.view') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="3">
              <v-card
                class="box-person"
                height="140"
              >
                <v-card-title class="align-end">
                  {{ alertInfo.cnt_all_person }}
                </v-card-title>
                <v-card-subtitle class="align-end">
                  {{ $t('dashboard.cards.person.subtitle') }}
                </v-card-subtitle>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    text
                    dark
                    :to="{ name: 'NaturalPerson' }"
                    data-cy="to_NaturalPerson_dashboard_btn"
                  >
                    {{ $t('dashboard.cards.person.buttons.view') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </section>
      </v-container>
    </v-card>
    <br>
    <v-card
      class="mx-auto"
      width="100%"
    >
      <v-container
        fill-height
        fluid
      >
        <loading
          :active.sync="requestLoading"
          :is-full-page="false"
        />
        <v-row>
          <v-col cols="auto">
            <span class="title">{{ $t('dashboard.charts.line.title') }}</span>
            <div
              style="max-width: 70vh"
              data-cy="alert_line_chart"
            >
              <Linechart
                :chart-data="barChartData"
                :options="{
                  responsive: true,
                  scales: {
                    yAxes: [
                      {
                        id: 'all_alert',
                        ticks: {
                          beginAtZero: true,
                          //stepSize: 10,
                          min: 0,
                          precision: 0,

                        }
                      }
                    ]
                  }
                }"
              />
            </div>
          </v-col>
          <v-col cols="auto">
            <span class="title">{{ $t('dashboard.charts.donut.title') }}</span>
            <div
              style="max-width: 70vh"
              data-cy="alert_donut_chart"
            >
              <Donut :chart-data="donutChartData" />
            </div>
          </v-col>
        </v-row>
        <!-- <section class="content">
                    <loading :active.sync="requestLoading" :is-full-page="false"></loading>
                    <div class="row">

                        <div class="col-md-6">
                            <div class="box box-info">
                                <div class="box-header with-border">
                                    <h3 class="box-title">{{ $t('dashboard.charts.line.title') }}</h3>
                                    <div class="box-tools pull-right">
                                        <button type="button" class="btn btn-box-tool" data-widget="collapse"><i class="fa fa-minus"></i>
                                        </button>
                                        <button type="button" class="btn btn-box-tool" data-widget="remove"><i class="fa fa-times"></i></button>
                                    </div>
                                </div>
                                <div class="box-body">
                                    <div class="chart">
                                        <Linechart :chartData="barChartData"></Linechart>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="box box-danger">
                                <div class="box-header with-border">
                                    <h3 class="box-title">{{ $t('dashboard.charts.donut.title') }}</h3>
                                    <div class="box-tools pull-right">
                                        <button type="button" class="btn btn-box-tool" data-widget="collapse"><i class="fa fa-minus"></i>
                                        </button>
                                        <button type="button" class="btn btn-box-tool" data-widget="remove"><i class="fa fa-times"></i></button>
                                    </div>
                                </div>
                                <div class="box-body">
                                    <Donut :chartData="donutChartData"></Donut>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> -->
      </v-container>
    </v-card>
  </div>
</template>
<script>
// import CountrySelector from '@/components/forms/CountrySelector'
import Donut from '@/components/charts/Donut.js'
import Linechart from '@/components/charts/Line.js'
import { mapGetters, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    Donut,
    Linechart
  },
  data () {
    return {
      form: {
        name: '',
        id: '',
        nationality: '',
        dob: null
      }
    }
  },
  validations () {
    return {
      form: {
        name: {
          required
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      alertInfo: 'scan/alertInfo',
      requestLoading: 'scan/loading'
    }),
    barChartData () {
      var months = this.$t('dashboard.charts.line.months'); var // ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        bar_chart_data = (this.alertInfo.bar_chart_data || [])
      var date = new Date()
      var _data = []

      do {
        var record = {
          month: date.getMonth() + 1,
          year: date.getFullYear(),
          count: 0
        }

        for (var i = bar_chart_data.length - 1; i >= 0; i--) {
          if (parseInt(bar_chart_data[i].month) === parseInt(record.month) && parseInt(bar_chart_data[i].year) === parseInt(record.year)) {
            record = bar_chart_data[i]
          }
        }

        _data.push(record)
        date.setMonth(date.getMonth() - 1)
      } while (_data.length < 12)

      if (_data) {
        _data.reverse()

        return {
          labels: _data.map(data => {
            return months[data.month - 1] + ' ' + data.year
          }),
          datasets: [
            {
              yAxisID: 'all_alert',
              label: this.$t('dashboard.charts.line.labels.alert'),
              backgroundColor: '#f87979',
              data: _data.map(data => {
                return data.count
              })
            }
          ]
        }
      }
      return null
    },
    donutChartData () {
      return {
        labels: (this.alertInfo.donut_chart_data || []).map(data => {
          return this.$t(`dashboard.charts.donut.labels.${parseInt(data.status) === 1 ? 'true_hit' : (parseInt(data.status) === 2 ? 'false_hit' : 'pending')}`)
        }),
        datasets: [
          {
            // backgroundColor: ['#3366CC','#DC3912','#FF9900','#109618','#990099','#3B3EAC','#0099C6','#DD4477','#66AA00','#B82E2E','#316395','#994499','#22AA99','#AAAA11','#6633CC','#E67300','#8B0707','#329262','#5574A6','#3B3EAC'],
            backgroundColor: [
              '#41B883',
              '#00D8FF',
              '#E46651',
              '#E62E77',
              '#C0D42A',
              '#DD1B16'
            ],
            data: (this.alertInfo.donut_chart_data || []).map(data => {
              return data.count
            })
          }
        ]
      }
    }
  },
  methods: {
    ...mapActions({
      updateAlertInfo: 'scan/updateAlertInfo'
    })
  },
  mounted () {
    this.updateAlertInfo()
  }
}
</script>

<style scoped>
.box-alert-pending {
    /*background-image: linear-gradient(126deg, rgba(101, 101, 101, 0.09) 0%, rgba(101, 101, 101, 0.09) 68%,rgba(200, 200, 200, 0.09) 68%, rgba(200, 200, 200, 0.09) 100%),linear-gradient(164deg, rgba(238, 238, 238, 0.03) 0%, rgba(238, 238, 238, 0.03) 90%,rgba(14, 14, 14, 0.03) 90%, rgba(14, 14, 14, 0.03) 100%),linear-gradient(27deg, rgba(214, 214, 214, 0.04) 0%, rgba(214, 214, 214, 0.04) 34%,rgba(104, 104, 104, 0.04) 34%, rgba(104, 104, 104, 0.04) 100%),linear-gradient(175deg, rgba(20, 20, 20, 0.01) 0%, rgba(20, 20, 20, 0.01) 4%,rgba(9, 9, 9, 0.01) 4%, rgba(9, 9, 9, 0.01) 100%),linear-gradient(257deg, rgba(14, 14, 14, 0.01) 0%, rgba(14, 14, 14, 0.01) 28%,rgba(164, 164, 164, 0.01) 28%, rgba(164, 164, 164, 0.01) 100%),linear-gradient(311deg, rgba(68, 68, 68, 0.07) 0%, rgba(68, 68, 68, 0.07) 33%,rgba(213, 213, 213, 0.07) 33%, rgba(213, 213, 213, 0.07) 100%),linear-gradient(244deg, rgba(43, 43, 43, 0.02) 0%, rgba(43, 43, 43, 0.02) 80%,rgba(161, 161, 161, 0.02) 80%, rgba(161, 161, 161, 0.02) 100%),linear-gradient(130deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.04) 49%,rgba(105, 105, 105, 0.04) 49%, rgba(105, 105, 105, 0.04) 100%),linear-gradient(90deg, rgb(190, 33, 111),rgb(97, 3, 0)); */
    /*background-image: radial-gradient(circle at 75% 26%, rgba(116, 116, 116,0.04) 0%, rgba(116, 116, 116,0.04) 50%,rgba(222, 222, 222,0.04) 50%, rgba(222, 222, 222,0.04) 100%),radial-gradient(circle at 62% 63%, rgba(60, 60, 60,0.04) 0%, rgba(60, 60, 60,0.04) 50%,rgba(152, 152, 152,0.04) 50%, rgba(152, 152, 152,0.04) 100%),radial-gradient(circle at 80% 64%, rgba(108, 108, 108,0.04) 0%, rgba(108, 108, 108,0.04) 50%,rgba(252, 252, 252,0.04) 50%, rgba(252, 252, 252,0.04) 100%),radial-gradient(circle at 97% 83%, rgba(184, 184, 184,0.04) 0%, rgba(184, 184, 184,0.04) 50%,rgba(137, 137, 137,0.04) 50%, rgba(137, 137, 137,0.04) 100%),radial-gradient(circle at 60% 7%, rgba(69, 69, 69,0.04) 0%, rgba(69, 69, 69,0.04) 50%,rgba(211, 211, 211,0.04) 50%, rgba(211, 211, 211,0.04) 100%),linear-gradient(90deg, rgb(212, 245, 239),rgb(253, 123, 174));*/
    background-image: radial-gradient(circle at 79% 30%, rgba(230, 230, 230,0.04) 0%, rgba(230, 230, 230,0.04) 50%,rgba(12, 12, 12,0.04) 50%, rgba(12, 12, 12,0.04) 100%),radial-gradient(circle at 53% 89%, rgba(210, 210, 210,0.04) 0%, rgba(210, 210, 210,0.04) 50%,rgba(24, 24, 24,0.04) 50%, rgba(24, 24, 24,0.04) 100%),radial-gradient(circle at 92% 89%, rgba(17, 17, 17,0.04) 0%, rgba(17, 17, 17,0.04) 50%,rgba(205, 205, 205,0.04) 50%, rgba(205, 205, 205,0.04) 100%),radial-gradient(circle at 21% 13%, rgba(124, 124, 124,0.04) 0%, rgba(124, 124, 124,0.04) 50%,rgba(243, 243, 243,0.04) 50%, rgba(243, 243, 243,0.04) 100%),radial-gradient(circle at 77% 0%, rgba(16, 16, 16,0.04) 0%, rgba(16, 16, 16,0.04) 50%,rgba(130, 130, 130,0.04) 50%, rgba(130, 130, 130,0.04) 100%),linear-gradient(90deg, rgb(247, 101, 31),rgb(249, 105, 208));
}
.box-single-scan {
    /*background-image: linear-gradient(169deg, rgba(214, 214, 214, 0.05) 0%, rgba(214, 214, 214, 0.05) 25%,rgba(147, 147, 147, 0.05) 25%, rgba(147, 147, 147, 0.05) 50%,rgba(106, 106, 106, 0.05) 50%, rgba(106, 106, 106, 0.05) 75%,rgba(58, 58, 58, 0.05) 75%, rgba(58, 58, 58, 0.05) 100%),linear-gradient(2deg, rgba(151, 151, 151, 0.05) 0%, rgba(151, 151, 151, 0.05) 25%,rgba(118, 118, 118, 0.05) 25%, rgba(118, 118, 118, 0.05) 50%,rgba(60, 60, 60, 0.05) 50%, rgba(60, 60, 60, 0.05) 75%,rgba(62, 62, 62, 0.05) 75%, rgba(62, 62, 62, 0.05) 100%),linear-gradient(260deg, rgba(84, 84, 84, 0.05) 0%, rgba(84, 84, 84, 0.05) 25%,rgba(74, 74, 74, 0.05) 25%, rgba(74, 74, 74, 0.05) 50%,rgba(232, 232, 232, 0.05) 50%, rgba(232, 232, 232, 0.05) 75%,rgba(88, 88, 88, 0.05) 75%, rgba(88, 88, 88, 0.05) 100%),linear-gradient(89deg, rgba(191, 191, 191, 0.05) 0%, rgba(191, 191, 191, 0.05) 25%,rgba(64, 64, 64, 0.05) 25%, rgba(64, 64, 64, 0.05) 50%,rgba(57, 57, 57, 0.05) 50%, rgba(57, 57, 57, 0.05) 75%,rgba(86, 86, 86, 0.05) 75%, rgba(86, 86, 86, 0.05) 100%),linear-gradient(26deg, rgba(189, 189, 189, 0.05) 0%, rgba(189, 189, 189, 0.05) 25%,rgba(134, 134, 134, 0.05) 25%, rgba(134, 134, 134, 0.05) 50%,rgba(143, 143, 143, 0.05) 50%, rgba(143, 143, 143, 0.05) 75%,rgba(12, 12, 12, 0.05) 75%, rgba(12, 12, 12, 0.05) 100%),linear-gradient(90deg, rgb(168, 254, 0),rgb(75, 207, 73));*/
    background-image: radial-gradient(circle at 97% 32%, rgba(131, 131, 131,0.05) 0%, rgba(131, 131, 131,0.05) 50%,rgba(20, 20, 20,0.05) 50%, rgba(20, 20, 20,0.05) 100%),radial-gradient(circle at 61% 40%, rgba(35, 35, 35,0.05) 0%, rgba(35, 35, 35,0.05) 50%,rgba(239, 239, 239,0.05) 50%, rgba(239, 239, 239,0.05) 100%),radial-gradient(circle at 47% 73%, rgba(122, 122, 122,0.05) 0%, rgba(122, 122, 122,0.05) 50%,rgba(5, 5, 5,0.05) 50%, rgba(5, 5, 5,0.05) 100%),linear-gradient(90deg, rgb(0, 209, 117),rgb(205, 241, 44));
}
.box-company {background-image: linear-gradient(328deg, rgba(29, 29, 29, 0.05) 0%, rgba(29, 29, 29, 0.05) 25%,rgba(226, 226, 226, 0.05) 25%, rgba(226, 226, 226, 0.05) 50%,rgba(21, 21, 21, 0.05) 50%, rgba(21, 21, 21, 0.05) 75%,rgba(216, 216, 216, 0.05) 75%, rgba(216, 216, 216, 0.05) 100%),linear-gradient(172deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 25%,rgba(108, 108, 108, 0.05) 25%, rgba(108, 108, 108, 0.05) 50%,rgba(21, 21, 21, 0.05) 50%, rgba(21, 21, 21, 0.05) 75%,rgba(236, 236, 236, 0.05) 75%, rgba(236, 236, 236, 0.05) 100%),linear-gradient(207deg, rgba(153, 153, 153, 0.05) 0%, rgba(153, 153, 153, 0.05) 25%,rgba(83, 83, 83, 0.05) 25%, rgba(83, 83, 83, 0.05) 50%,rgba(5, 5, 5, 0.05) 50%, rgba(5, 5, 5, 0.05) 75%,rgba(82, 82, 82, 0.05) 75%, rgba(82, 82, 82, 0.05) 100%),linear-gradient(297deg, rgba(26, 26, 26, 0.05) 0%, rgba(26, 26, 26, 0.05) 25%,rgba(223, 223, 223, 0.05) 25%, rgba(223, 223, 223, 0.05) 50%,rgba(232, 232, 232, 0.05) 50%, rgba(232, 232, 232, 0.05) 75%,rgba(153, 153, 153, 0.05) 75%, rgba(153, 153, 153, 0.05) 100%),linear-gradient(204deg, rgba(120, 120, 120, 0.05) 0%, rgba(120, 120, 120, 0.05) 25%,rgba(191, 191, 191, 0.05) 25%, rgba(191, 191, 191, 0.05) 50%,rgba(246, 246, 246, 0.05) 50%, rgba(246, 246, 246, 0.05) 75%,rgba(123, 123, 123, 0.05) 75%, rgba(123, 123, 123, 0.05) 100%),linear-gradient(90deg, rgb(32,234,255),rgb(37,116,255));}
.box-person {background-image: linear-gradient(44deg, rgba(243, 243, 243, 0.05) 0%, rgba(243, 243, 243, 0.05) 33.333%,rgba(79, 79, 79, 0.05) 33.333%, rgba(79, 79, 79, 0.05) 66.666%,rgba(9, 9, 9, 0.05) 66.666%, rgba(9, 9, 9, 0.05) 99.999%),linear-gradient(97deg, rgba(150, 150, 150, 0.05) 0%, rgba(150, 150, 150, 0.05) 33.333%,rgba(34, 34, 34, 0.05) 33.333%, rgba(34, 34, 34, 0.05) 66.666%,rgba(40, 40, 40, 0.05) 66.666%, rgba(40, 40, 40, 0.05) 99.999%),linear-gradient(29deg, rgba(56, 56, 56, 0.05) 0%, rgba(56, 56, 56, 0.05) 33.333%,rgba(226, 226, 226, 0.05) 33.333%, rgba(226, 226, 226, 0.05) 66.666%,rgba(221, 221, 221, 0.05) 66.666%, rgba(221, 221, 221, 0.05) 99.999%),linear-gradient(90deg, rgb(163, 238, 211),rgb(149, 75, 252));}
.col-lg-3.col-xs-6.dashboard-btn {
		cursor: pointer;
}
</style>
