import { Doughnut, mixins } from 'vue-chartjs'

export default {
  extends: Doughnut,
  mixins: [mixins.reactiveProp],
  props: ['chartData', 'options'],
  mounted () {
    // this.renderChart({
    //   labels: ['Pending', 'Recommend To Approve', 'Approved', 'Recommend To Reject', 'Rejected', 'Completed'],
    //   datasets: [
    //     {
    //       backgroundColor: [
    //         '#41B883',
    //         '#E46651',
    //         '#00D8FF',
    //         '#E62E77',
    //         '#C0D42A',
    //         '#DD1B16'
    //       ],
    //       data: [31, 23, 26, 15, 27, 16]
    //     }
    //   ]
    // }, {responsive: true, maintainAspectRatio: false})

    // this.addPlugin({
    //   afterDatasetsDraw: function(chartInstance, easing) {
    //     // To only draw at the end of animation, check for easing === 1
    //     var ctx = chartInstance.chart.ctx;
    //     chartInstance.data.datasets.forEach(function(dataset, i) {
    //       var meta = chartInstance.getDatasetMeta(i);
    //       if (!meta.hidden) {
    //         meta.data.forEach(function(element, index) {
    //           // Draw the text in black, with the specified font
    //           ctx.fillStyle = 'grey';
    //           var fontSize = 16;
    //           var fontStyle = 'normal';
    //           var fontFamily = 'Helvetica Neue';
    //           ctx.font = Chart.helpers.fontString(fontSize, fontStyle, fontFamily);
    //           // Just naively convert to string for now
    //           var dataString = dataset.data[index].toString();
    //           // Make sure alignment settings are correct
    //           ctx.textAlign = 'center';
    //           ctx.textBaseline = 'middle';
    //           var padding = 5;
    //           var position = element.tooltipPosition();
    //           ctx.fillText(dataString + '%', position.x, position.y - (fontSize / 2) - padding);
    //         });
    //       }
    //     });
    //   }
    // });

    this.renderChart(
      this.chartData,
      Object.assign({
        responsive: true,
        maintainAspectRatio: false
        // legend: {
        //   display: true,

        //   // generateLabels changes from chart to chart,  check the source,
        //   // this one is from the doughut :
        //   // https://github.com/chartjs/Chart.js/blob/master/src/controllers/controller.doughnut.js#L42
        //   labels: {
        //     generateLabels: function(chart) {
        //       console.log(chart.data);
        //       var data = chart.data;
        //       if (data.labels.length && data.datasets.length) {
        //         return data.labels.map(function(label, i) {
        //           var meta = chart.getDatasetMeta(0);
        //           var ds = data.datasets[0];
        //           var arc = meta.data[i];
        //           var custom = arc && arc.custom || {};
        //           var getValueAtIndexOrDefault = _chart.helpers.getValueAtIndexOrDefault;
        //           var arcOpts = chart.options.elements.arc;
        //           var fill = custom.backgroundColor ? custom.backgroundColor : getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor);
        //           var stroke = custom.borderColor ? custom.borderColor : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
        //           var bw = custom.borderWidth ? custom.borderWidth : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);
        //             return {
        //             // And finally :
        //             text: ds.data[i] + "% of the time " + label,
        //             fillStyle: fill,
        //             strokeStyle: stroke,
        //             lineWidth: bw,
        //             hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
        //             index: i
        //           };
        //         });
        //       }
        //       return [];
        //     }
        //   }
        // }
      }, this.options)
    )

    // this.generateLegend()
  }
}
